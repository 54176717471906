export default {
  title: 'お問い合わせ',
  tags: {
    required: '必須',
    any: '任意'
  },
  titles: {
    contact: 'お問い合わせ',
    personal: '個人情報',
    company: '企業情報',
    address: '住所'
  },
  sub: {
    contactType: 'お問い合わせの種類',
    contactDetail: '内容',
    name: 'お名前',
    email: 'メールアドレス',
    tel: '電話番号',
    companyName: '企業名',
    postalCode: '郵便番号',
    prefectures: '都道府県',
    city: '市区町村',
    other: '番地・マンション名・部屋番号'
  },
  placeholder: {
    contactType: { label: '選択してください', value: 'defaultPlaceholder' },
    contactDetail: 'お問い合わせ内容を記載して下さい。',
    name: '山田 太郎',
    email: 'sample@sample.ne.jp',
    tel: '08012345678',
    companyName: '株式会社Futurize',
    postalCode: '123-4567',
    prefecture: { label: '', value: '' }
  },
  contactList: [
    { label: 'ご注文について', value: 'yourOrder' },
    { label: '商品について', value: 'theProduct' },
    { label: '配送について', value: 'regardingDelivery' },
    { label: '返品・交換について', value: 'refund' },
    { label: '会員情報について', value: 'memberInformation' },
    { label: 'メディア掲載について', value: 'mediaPublication' },
    { label: '店舗販売について', value: 'storeSales' },
    { label: 'その他', value: 'other' },
  ],
  prefectures: [
    { label: '北海道', value: 'hokkaido' },
    { label: '青森県', value: 'aomori' },
    { label: '岩手県', value: 'iwate' },
    { label: '宮城県', value: 'miyagi' },
    { label: '秋田県', value: 'akita' },
    { label: '山形県', value: 'yamagata' },
    { label: '福島県', value: 'fukushima' },
    { label: '茨城県', value: 'ibaragi' },
    { label: '栃木県', value: 'tochigi' },
    { label: '群馬県', value: 'gunma' },
    { label: '埼玉県', value: 'saitama' },
    { label: '千葉県', value: 'chiba' },
    { label: '東京都', value: 'tokyo' },
    { label: '神奈川県', value: 'kanagawa' },
    { label: '新潟県', value: 'niigata' },
    { label: '富山県', value: 'toyama' },
    { label: '石川県', value: 'ishikawa' },
    { label: '福井県', value: 'fukui' },
    { label: '山梨県', value: 'yamanashi' },
    { label: '長野県', value: 'nagano' },
    { label: '岐阜県', value: 'gifu' },
    { label: '静岡県', value: 'shizuoka' },
    { label: '愛知県', value: 'aichi' },
    { label: '三重県', value: 'mie' },
    { label: '滋賀県', value: 'shiga' },
    { label: '京都府', value: 'kyoto' },
    { label: '大阪府', value: 'osaka' },
    { label: '兵庫県', value: 'hyogo' },
    { label: '奈良県', value: 'nara' },
    { label: '和歌山県', value: 'wakayama' },
    { label: '鳥取県', value: 'tottori' },
    { label: '島根県', value: 'shimane' },
    { label: '岡山県', value: 'okayama' },
    { label: '広島県', value: 'hiroshima' },
    { label: '山口県', value: 'yamaguchi' },
    { label: '徳島県', value: 'tokushima' },
    { label: '香川県', value: 'kagawa' },
    { label: '愛媛県', value: 'ehime' },
    { label: '高知県', value: 'kochi' },
    { label: '福岡県', value: 'fukuoka' },
    { label: '佐賀県', value: 'saga' },
    { label: '長崎県', value: 'nagasaki' },
    { label: '熊本県', value: 'kumamoto' },
    { label: '大分県', value: 'oita' },
    { label: '宮崎県', value: 'miyazaki' },
    { label: '鹿児島県', value: 'kagoshima' },
    { label: '沖縄県', value: 'okinawa' }
  ],
  submit: 'お問い合わせ内容を送信'
}
