/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { MixinInner, rem, lineHeight } from './style'
import { BASE_WHITE_COLOR, BRAND_COLOR } from './.style'

import myLabels from '../documents/contact'

export const contactPage = css`
  ${MixinInner};
  margin-top: 5.6%;
  margin-bottom: 6.6%;

  @media (max-width: 768px) {
    margin-top: ${rem(35)};
    margin-bottom: ${rem(40)};
  }

  h2 {
    padding-bottom: ${rem(60)};
    font-size: ${rem(32)};
    line-height: ${lineHeight(32, 51.2)};
    font-weight: bold;
    text-align: center;

    @media (max-width: 768px) {
      font-size: ${rem(24)};
      line-height: ${lineHeight(24, 38.4)};
    }
  }

  form { text-align: center; }
`

export const contactLargeItem = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: ${rem(26)};

  @media (max-width: 768px) {
    display: block;
  }

  &:not(:nth-of-type(1)) {
    padding-top: ${rem(23)};
  }
  &:not(:nth-last-of-type(1)) {
    border-bottom: 1px solid #E0E0E0;
  }
`

export const contactLargeItemLabel = css`
  padding-right: ${rem(70)};
  text-align: left;
  width: 20%;

  @media (max-width: 768px) {
    padding-right: 0;
    width: 100%;
  }

  label {
    display: block;
    padding-bottom: ${rem(6)};
    font-size: ${rem(16)};
    font-weight: bold;
    line-height: ${lineHeight(16, 14)}
  }
`

export const contactFormRequiredLabel = css`
  padding: 0 ${rem(5)};
  font-size: ${rem(10)};
  line-height: ${lineHeight(10, 24)};
  color: ${BASE_WHITE_COLOR};
  border-radius: ${rem(2)};
`

export const isColorRequired = css`
  background-color: #F03B3B
`

export const isColorAny = css`
  background-color: #999999
`

export const contactFormBody = css`
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const contactFormItem = css`
  padding-bottom: ${rem(12)};
  text-align: left;

  p {
    padding-bottom: ${rem(5)};
    font-size: ${rem(16)};
    line-height: ${lineHeight(16, 24)};
  }

  textarea,
  div { width: 100%; }
`

export const contactTextArea = css`
  padding: ${rem(8)} ${rem(14)};
  font-size: ${rem(16)};
  line-height: ${lineHeight(16, 24)};
  height: ${rem(114)};
  background-color: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D1D1D1;

  @media (max-width: 768px) {
    font-size: ${rem(14)};
    line-height: ${lineHeight(14, 20)};
  }
`

export const contactFormSelect = css`
  position: relative;
  background-color: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D1D1D1;

  .p-form_control_selected > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div:nth-child(1) {
      margin: 0 ${rem(14)};
      padding: 0;
      width: 95%;

      @media (max-width: 768px) {
        margin: 0 ${rem(12)};
      }

      > div:nth-child(1) {
        font-size: ${rem(16)};
        line-height: ${lineHeight(16, 24)};

        @media (max-width: 768px) {
          font-size: ${rem(13)};
          line-height: ${lineHeight(13, 24)};
        }
      }
    }
    > div:nth-child(2) {
      width: 5%;
    }
  }
`

export const contactFormInput = css`
  padding: ${rem(8)} ${rem(14)};
  font-size: ${rem(16)};
  line-height: ${lineHeight(16, 24)};
  background-color: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D1D1D1;

  @media (max-width: 768px) {
    font-size: ${rem(14)};
    line-height: ${lineHeight(14, 20)};
  }
`

export const contactFlexItem = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }

  div {
    margin-right: ${rem(20)};
    width: ${rem(220)};
  }
`

export const contactFormSubmitButton = css`
  display: inline-block;
  padding: ${rem(14)} ${rem(100)} ${rem(17)};
  font-size: ${rem(18)};
  font-weight: bold;
  line-height: ${lineHeight(18, 24)};
  color: ${BASE_WHITE_COLOR};
  background: ${BRAND_COLOR};
  border-radius: ${rem(4)};

  @media (max-width: 768px) {
    padding: ${rem(16)} 0 ${rem(14)};
    font-size: ${rem(16)};
    line-height: ${lineHeight(16, 20)};
    width: 100%;
  }

  &:hover {
    background: #EA8B22;
  }

  &[disabled] {
    pointer-events: none;
    color: #D1D1D1;
    background: ${BASE_WHITE_COLOR};
    border: 2px solid #D1D1D1;
  }
`

export const isFullSizeContent = css`
  width: 100%;
`

export const isRequiredFormContent = css`
  background: #FFF0F0;
  border: 1px solid #F03B3B;
`
